<template>
  <div>
    <div
      v-if="label || hint"
      :class="['flex', label ? 'justify-between' : 'justify-end' ]"
    >
      <label
        v-if="label"
        :for="id"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <span
        v-if="hint"
        class="text-sm text-gray-500"
      >
        {{ hint }}
      </span>
    </div>

    <div :class="{ 'mt-1': label || hint }">
      <select
        :id="id"
        :name="name"
        :disabled="disabled"
        :form="form"
        :required="required"
        :autocomplete="autocomplete"
        class="block w-full shadow-sm sm:text-sm rounded-md"
        :class="[classes, `qa-${qaClass}`]"
        @change="onChange"
      >
        <option
          v-for="option in options"
          :key="option[valueProp]"
          :value="option[valueProp]"
          :selected="isSelected(option)"
          :class="`qa-${qaClass}-option`"
        >
          {{ option[labelProp] }}
        </option>
      </select>
    </div>

    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },

    options: {
      type: Array,
      required: true,
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    valueProp: {
      type: String,
      default: 'value',
    },

    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    form: {
      type: String,
      default: null,
    },

    autocomplete: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },

    error: {
      type: String,
      default: '',
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { valueProp, modelValue, disabled, error } = toRefs(props);

    const classes = computed(() => [
      disabled.value ? 'bg-gray-100' : '',
      error.value
        ? 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
        : 'focus:ring-primary-500 focus:border-primary-500 border-gray-300',
    ]);

    const isSelected = (option) => option[valueProp.value] === modelValue.value;
    const onChange = (event) => {
      emit('update:modelValue', event.target.value);
    };

    return {
      classes,
      optionQaClass: computed(() => (props.qaClass !== '' ? `${props.qaClass}-option` : '')),

      isSelected,
      onChange,
    };
  },
};
</script>
