<template>
  <Badge
    :color="color"
  >
    {{ stateName }}
  </Badge>
  <div
    :class="['mx-2 flex-auto min-w-4 border-t border-b border-gray-400',
             store[transitionType].automatic ? 'border-dashed' : 'border-solid']"
  />
  <div
    v-if="editable"
    class="border rounded-md px-2 py-2.5"
  >
    <BaseCheckbox
      v-model="isAutomatic"
      :label="$t('components.assessment_workflow_setting.automatic')"
      :qa-class="`${transitionName}-automatic-checkbox`"
    />
    <div class="mt-2 space-y-1">
      <div class="w-32">
        <BaseInput
          v-model="transitionInterval"
          :disabled="!isAutomatic"
          :error="intervalError"
          :qa-class="transitionName"
        >
          <template #trailing-addon>
            <div class="justify-center w-16">
              {{ unit }}
            </div>
          </template>
        </BaseInput>
      </div>
      <p class="text-gray-500 text-sm font-normal text-center">
        {{ $t(`components.assessment_workflow_setting.${transitionName}.text`) }}
      </p>
    </div>
  </div>
  <span
    v-else
    :class="`qa-${transitionName}-label`"
  >
    {{ transitionLabel }}
  </span>
  <div
    :class="['relative arrow-right mx-2  min-w-4 flex-auto border-t border-b border-gray-400',
             store[transitionType].automatic ? 'border-dashed' : 'border-solid']"
  />
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAssessmentWorkflowSettingsStore } from '@/components/WorkflowSettingsCard/stores/assessmentWorkflowSettingsStore';

export default {
  name: 'StateTransition',
  components: {
  },
  props: {
    stateName: {
      type: String,
      required: true,
    },
    transitionName: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'white',
    },
    intervalUnit: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const transitionType = props.transitionName.replace(/_([a-z])/g, (match, group) => group.toUpperCase());
    const store = useAssessmentWorkflowSettingsStore();

    const transitionLabel = computed(() => {
      if (!store[transitionType].automatic) return t('components.assessment_workflow_setting.manual');
      return t(
        `components.assessment_workflow_setting.${props.transitionName}.interval`,
        Number(store[transitionType].interval),
      );
    });

    const isAutomatic = ref(store[transitionType].automatic);
    const transitionInterval = ref(isAutomatic.value ? store[transitionType].interval : '-');

    watch(() => isAutomatic.value, (newValue, _) => {
      store[transitionType].automatic = newValue;
      if (newValue) {
        transitionInterval.value = store[transitionType].interval;
      } else {
        transitionInterval.value = '-';
      }
    });

    watch(() => transitionInterval.value, (newInterval, _) => {
      if (newInterval !== '-') {
        if (store.validateDuration(Number(newInterval), transitionType).valid) {
          store[transitionType].interval = Number(newInterval);
        }
      }
    });

    const intervalError = computed(() => {
      if (transitionInterval.value === '-') return '';
      return store.validateDuration(Number(transitionInterval.value), transitionType).error;
    });

    const unit = computed(() => {
      const quantity = (transitionInterval.value === '-' || transitionInterval.value > 1) ? 2 : 1;
      return t(
        `components.assessment_workflow_setting.time_intervals.${props.intervalUnit}`,
        quantity,
      );
    });

    return {
      store,
      transitionType,
      transitionLabel,
      isAutomatic,
      transitionInterval,
      unit,
      intervalError,
    };
  },
};
</script>
