<template>
  <Card>
    <div class="flex flex-col p-6">
      <div class="flex w-full space-x-4 justify-between">
        <div>
          <span class="text-gray-900 text-lg leading-6 font-medium block">
            {{ $t('components.assessment_workflow_setting.header') }}
          </span>
          <span
            v-if="inheritsWorkflow"
            class="text-gray-500 text-sm leading-5 block mt-1"
          >
            {{ $t('components.assessment_workflow_setting.help_text_inherited') }}
            <a
              :href="$t('components.assessment_workflow_setting.support_link')"
              class="text-primary-500 text-sm leading-5 inline-block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('defaults.more_information') }}
            </a>
          </span>
          <span
            v-else
            class="text-gray-500 text-sm leading-5 block mt-1"
          >
            {{ $t('components.assessment_workflow_setting.help_text') }}
            <a
              :href="$t('components.assessment_workflow_setting.support_link')"
              class="text-primary-500 text-sm leading-5 inline-block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t('defaults.more_information') }}
            </a>
          </span>
        </div>
        <div>
          <BaseButton
            v-if="!inheritsWorkflow"
            :as="buttonType"
            :href="linkToAssessment"
            :is-disabled="isDisabled"
            :light-colors="true"
            icon="PencilIcon"
            qa-class="edit-workflow-button"
            @click="showModal = true"
          >
            {{ $t('components.assessment_workflow_setting.edit_button') }}
          </BaseButton>
        </div>
      </div>
      <div class="grow min-w-0 mt-4 md:mt-0 overflow-x-auto">
        <div class="w-full">
          <div class="flex">
            <div class="m-0 flex w-full min-w-[985px] items-center py-8">
              <StateTransition
                :state-name="$t('activerecord.enums.assessment.status.draft')"
                color="gray"
                transition-name="activation_transition"
              />
              <StateTransition
                :state-name="$t('activerecord.enums.assessment.status.active')"
                color="green"
                transition-name="completion_transition"
              />
              <StateTransition
                :state-name="$t('activerecord.enums.assessment.status.completed')"
                color="blue"
                transition-name="finalization_transition"
              />
              <Badge
                :color="store.getFinalStatusColor(store.finalStatus)"
                class="qa-final-status"
              >
                {{ $t(`components.assessment_workflow_setting.final_status.${store.finalStatus}`) }}
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>

  <input
    type="hidden"
    :name="`${formAttributePrefix}[auto_activation]`"
    :value="store.activationTransition.automatic"
  >
  <input
    type="hidden"
    :name="`${formAttributePrefix}[auto_completion]`"
    :value="store.completionTransition.automatic"
  >
  <input
    type="hidden"
    :name="`${formAttributePrefix}[auto_finalization]`"
    :value="store.finalizationTransition.automatic"
  >
  <input
    type="hidden"
    :name="`${formAttributePrefix}[interval_to_active]`"
    :value="store.durationInHours(store.activationTransition.interval)"
  >
  <input
    type="hidden"
    :name="`${formAttributePrefix}[interval_to_completed]`"
    :value="store.durationInDays(store.completionTransition.interval)"
  >
  <input
    type="hidden"
    :name="`${formAttributePrefix}[interval_to_final_status]`"
    :value="store.durationInWeeks(store.finalizationTransition.interval)"
  >
  <input
    type="hidden"
    :name="`${formAttributePrefix}[final_status]`"
    :value="store.finalStatus"
  >

  <WorkflowSettingsModal
    :open="showModal"
    @close="showModal = false"
  />
</template>

<script>
import Card from '@/components/generic/Card/Card.vue';
import { ref, watch } from 'vue';
import WorkflowSettingsModal from '@/components/WorkflowSettingsCard/WorkflowSettingsModal.vue';
import { useAssessmentWorkflowSettingsStore } from '@/components/WorkflowSettingsCard/stores/assessmentWorkflowSettingsStore';
import StateTransition from './StateTransition.vue';

export default {
  name: 'WorkflowSettingsCard',
  components: {
    Card,
    StateTransition,
    WorkflowSettingsModal,
  },
  props: {
    workflowSettings: {
      type: Object,
      required: true,
    },
    formAttributePrefix: {
      type: String,
      required: true,
    },
    linkToAssessment: {
      type: String,
      default: null,
      required: false,
    },
    assessmentCompletedAt: {
      type: String,
      default: null,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    inheritsWorkflow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const workflowSettings = ref(props.workflowSettings);

    const store = useAssessmentWorkflowSettingsStore();
    store.initAssessmentWorkflowSettings({ ...workflowSettings.value }, props.assessmentCompletedAt);

    watch(() => workflowSettings, (newValue) => {
      store.initAssessmentWorkflowSettings({ ...newValue }, props.assessmentCompletedAt);
    });

    const showModal = ref(false);
    return {
      store,
      showModal,
      buttonType: props.linkToAssessment !== null ? 'a' : 'button',
    };
  },
};

</script>
