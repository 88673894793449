<template>
  <div class="min-h-full flex flex-row justify-center items-center h-screen">
    <div class="lg:w-5/12 w-full px-8">
      <div class="space-y-8 overflow-auto py-4">
        <div class="sm:mx-auto sm:w-full sm:max-w-md text-center object-center content-center justify-center space-y-6">
          <img
            class="mx-auto w-48 h-auto"
            :src="logoUrl"
            alt="Applysia"
          >
          <h2
            class="text-gray-900 text-3xl leading-9 font-extrabold"
          >
            {{ $t('components.session.sign_in_header') }}
          </h2>
        </div>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <form
            id="login-form"
            :action="actionUrl"
            accept-charset="UTF-8"
            method="post"
            enctype="multipart/form-data"
            data-turbo="false"
            @submit="loading = true"
          >
            <input
              type="hidden"
              name="authenticity_token"
              :value="$csrfToken"
            >
            <div class="space-y-6">
              <template v-if="passwordAuthEnabled">
                <base-input
                  name="user[email]"
                  :model-value="userData.email"
                  :label="$t('components.session.fields.email')"
                  :error="getError('email')"
                  qa-class="login"
                />
                <base-input
                  name="user[password]"
                  :label="$t('components.session.fields.password')"
                  :type="'password'"
                  autocomplete="current-password"
                  qa-class="password"
                  :error="getError('password')"
                />
                <div class="flex flex-row justify-between w-full items-center">
                  <base-checkbox
                    name="user[remember_me]"
                    :label="$t('components.session.fields.remember_me')"
                  />
                  <div class="text-sm">
                    <a
                      class="
                        text-primary-500
                        text-sm
                        leading-5
                        font-medium
                        hover:text-primary-600
                        qa-reset-password-link"
                      :href="newPasswordUrl"
                    >
                      {{ $t('components.session.links.new_password') }}
                    </a>
                  </div>
                </div>
              </template>
              <div class="flex flex-col space-y-6">
                <base-button
                  v-if="passwordAuthEnabled"
                  class="btn"
                  type="submit"
                  :is-loading="loading"
                  form="login-form"
                  qa-class="login-button"
                >
                  {{ $t('components.session.buttons.sign_in') }}
                </base-button>
                <slot name="sso" />
                <div class="relative">
                  <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-t border-gray-300" />
                  </div>
                  <div class="relative flex justify-center text-sm">
                    <span class="px-2 bg-white text-gray-500">
                      {{ $t('components.session.separator.text') }}
                    </span>
                  </div>
                </div>
                <base-button
                  class="btn"
                  :is-primary="false"
                  qa-class="candidate-portal-link"
                  @click="toCandidatePortal"
                >
                  {{ $t('components.session.buttons.candidates_portal') }}
                </base-button>
                <div class="text-sm mt-2 w-full flex justify-center">
                  <a
                    class="text-primary-500 text-sm leading-5 font-medium hover:text-primary-600 qa-workspace-link"
                    :href="changeWorkspaceUrl"
                  >
                    {{ $t('components.session.change_workspace') }}
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="w-7/12 pt-8 h-screen hidden lg:block">
      <div class="bg-primary-50 rounded-tl-3xl w-full h-full flex flex-col justify-center items-center">
        <div class="w-4/6">
          <NewLoginFlowGraphic />
          <p class="text-primary-900 font-semibold text-lg">
            {{ $t('components.session.new_login_flow_text') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUserSessionPath, newPasswordPath, createRemoteSessionPath } from '@/util/url-helpers';
import { defineComponent, ref, computed } from 'vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import NewLoginFlowGraphic from '@/components/Session/Graphics/NewLoginFlowGraphic.vue';

export default defineComponent({
  name: 'LoginForm',
  components: {
    BaseInput,
    BaseCheckbox,
    BaseButton,
    NewLoginFlowGraphic,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    logoUrl: {
      type: String,
      default: '/applysia-logo.png',
    },
    passwordAuthEnabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const user = { ...props.userData };

    const createRemoteSessionUrl = createRemoteSessionPath();
    const newPasswordUrl = newPasswordPath();
    const actionUrl = createUserSessionPath();
    const loading = ref(false);

    function toCandidatePortal() {
      window.location.href = createRemoteSessionUrl;
    }

    const changeWorkspaceUrl = computed(() => {
      const rootDomain = window.location.hostname.split('.').slice(-2).join('.');
      return `${window.location.protocol}//${rootDomain}?changeWorkspace=true`;
    });

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    return {
      user,
      getError,
      toCandidatePortal,
      createRemoteSessionUrl,
      newPasswordUrl,
      actionUrl,
      loading,
      changeWorkspaceUrl,
    };
  },
});
</script>
