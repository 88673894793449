<template>
  <div>
    <div
      v-if="label || hint"
      :class="['flex', label ? 'justify-between' : 'justify-end' ]"
    >
      <label
        v-if="label"
        :for="id"
        class="flex text-sm font-medium text-gray-700"
      >
        <p>
          {{ label }}
        </p>
        <HeroiconOutline
          v-if="infoLink"
          icon="InformationCircleIcon"
          class="ml-1 h-5 w-5 text-black-700 cursor-pointer"
          aria-hidden="true"
          @click="openSupportPage"
        />
      </label>
      <span
        v-if="hint"
        class="text-sm text-gray-500"
      >
        {{ hint }}
      </span>
    </div>

    <div :class="inputWrapperCssClasses">
      <template v-if="hasAddonButton">
        <div class="relative flex items-stretch grow focus-within:z-10">
          <div
            v-if="hasLeadingIcon"
            class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-20"
          >
            <slot
              name="leading-icon"
              :error="error"
            >
              <HeroiconOutline
                :icon="leadingIcon"
                :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
                aria-hidden="true"
              />
            </slot>
          </div>
          <div class="relative w-full">
            <input
              :id="id"
              v-bind="furtherHtmlAttrs"
              ref="inputRef"
              v-model="value"
              :type="toggleType"
              :name="name"
              :disabled="disabled"
              :class="inputCssClasses"
              @input="onInput"
            >
            <span
              v-if="trailingUnit"
              class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
            >
              {{ trailingUnit }}
            </span>
          </div>
          <div
            v-if="hasTrailingIcon"
            :class="[
              'absolute z-10 inset-y-0 right-0 pr-3 flex items-center text-gray-300',
              trailingIconClickable ? '' : 'pointer-events-none']"
          >
            <slot
              name="trailing-icon"
              :error="error"
            >
              <HeroiconOutline
                :icon="trailingIcon"
                :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
                aria-hidden="true"
              />
            </slot>
          </div>
        </div>
        <div
          class="add-button"
        >
          <slot name="addon-button" />
        </div>
      </template>

      <template v-else>
        <div
          v-if="hasLeadingIcon"
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10"
        >
          <slot
            name="leading-icon"
            :error="error"
          >
            <HeroiconOutline
              :icon="leadingIcon"
              :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
              aria-hidden="true"
            />
          </slot>
        </div>
        <span
          v-else-if="hasLeadingAddon"
          class="inline-flex items-center justify-center px-3 rounded-l-md border border-r-0 border-gray-300
            bg-gray-50 text-gray-500 sm:text-sm min-w-9"
        >
          <slot name="leading-addon" />
        </span>

        <div class="relative w-full">
          <input
            :id="id"
            v-bind="furtherHtmlAttrs"
            ref="inputRef"
            v-model="value"
            :type="toggleType"
            :name="name"
            :disabled="disabled"
            :class="inputCssClasses"
            @input="onInput"
          >
          <span
            v-if="trailingUnit"
            class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
          >
            {{ trailingUnit }}
          </span>
          <button
            v-if="type === 'password'"
            type="button"
            class="top-1/2 -translate-y-1/2 text-gray-400 absolute right-2"
          >
            <EyeIcon
              v-if="toggleType === 'password'"
              class="h-5 w-5"
              @click="toggle"
            />
            <EyeOffIcon
              v-if="toggleType === 'text'"
              class="h-5 w-5"
              @click="toggle"
            />
          </button>
        </div>
        <div
          v-if="hasTrailingIcon"
          :class="[
            'absolute z-10 inset-y-0 right-0 pr-3 flex items-center text-gray-300',
            trailingIconClickable ? '' : 'pointer-events-none']"
        >
          <slot
            name="trailing-icon"
            :error="error"
          >
            <HeroiconOutline
              :icon="trailingIcon"
              :class="['h-5 w-5', error ? 'text-red-500' : 'text-gray-400']"
              aria-hidden="true"
            />
          </slot>
        </div>
        <span
          v-else-if="hasTrailingAddon"
          class="inline-flex items-center justify-center  px-3 rounded-r-md border border-l-0 border-gray-300
            bg-gray-50 text-gray-500 sm:text-sm min-w-9"
        >
          <slot name="trailing-addon" />
        </span>
      </template>
    </div>

    <div
      v-if="description"
      class="text-gray-500 text-sm mt-2"
    >
      {{ description }}
    </div>

    <p
      v-if="error && showErrorMessage"
      class="mt-2 text-sm text-red-600"
      :class="`qa-${qaClass}-error`"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';
import { computed, ref, toRefs, onMounted } from 'vue';
import HeroiconOutline from '@/components/HeroiconOutline.vue';

export default {
  components: {
    HeroiconOutline,
    EyeIcon,
    EyeOffIcon,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    leadingIcon: {
      type: String,
      default: null,
    },
    trailingIcon: {
      type: String,
      default: null,
    },
    trailingIconClickable: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    form: {
      type: String,
      default: null,
    },
    inputmode: {
      type: String,
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    minlength: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    step: {
      type: [String, Number],
      default: null,
    },
    tabindex: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },

    error: {
      type: String,
      default: '',
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    trailingUnit: {
      type: String,
      default: null,
    },
    asButton: {
      type: Boolean,
      default: false,
    },
    infoLink: {
      type: String,
      default: null,
    },
    qaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'addon-button-click'],
  setup(props, { emit, slots }) {
    const {
      leadingIcon, trailingIcon, label, hint, disabled, error,
      autocomplete, autofocus, form, inputmode, max, maxlength, min, minlength, placeholder, readonly, required,
      step, tabindex, title, pattern, asButton, qaClass,
    } = toRefs(props);

    const inputRef = ref();
    onMounted(() => {
      if (props.focus) { inputRef.value.focus(); }
    });

    const furtherHtmlAttrs = computed(() => ({
      autocomplete: autocomplete.value,
      autofocus: autofocus.value,
      form: form.value,
      inputmode: inputmode.value,
      max: max.value,
      maxlength: maxlength.value,
      min: min.value,
      minlength: minlength.value,
      placeholder: placeholder.value,
      readonly: readonly.value || asButton.value,
      required: required.value,
      step: step.value,
      tabindex: tabindex.value,
      title: title.value,
      pattern: pattern.value,
    }));

    const hasLeadingIcon = computed(() => Boolean(leadingIcon.value || slots['leading-icon']));
    const hasTrailingIcon = computed(() => Boolean(trailingIcon.value || slots['trailing-icon']));
    const hasIcon = computed(() => hasLeadingIcon.value || hasTrailingIcon.value);

    const hasLeadingAddon = computed(() => Boolean(slots['leading-addon']));
    const hasTrailingAddon = computed(() => Boolean(slots['trailing-addon']));
    const hasAddon = computed(() => hasLeadingAddon.value || hasTrailingAddon.value);

    const hasAddonButton = computed(() => Boolean(slots['addon-button']));

    const inputWrapperCssClasses = computed(() => ({
      'mt-1': label.value || hint.value,
      relative: hasIcon.value,
      flex: hasAddon.value || hasAddonButton.value,
      'rounded-md shadow-sm': hasIcon.value || hasAddon.value || hasAddonButton.value,
    }));

    const inputCssClasses = computed(() => [
      `block w-full sm:text-sm z-10 ${qaClass?.value !== '' ? `qa-${qaClass?.value}` : ''}`,
      {
        'shadow-sm': !(hasIcon.value || hasAddon.value || hasAddonButton.value),
        'flex-1 min-w-0 px-3 py-2': hasAddon.value,
        'rounded-md': !(hasAddon.value || hasAddonButton.value),
        'rounded-none': hasAddon.value || hasAddonButton.value,
        'rounded-l-md': (hasTrailingAddon.value || hasAddonButton.value) && !hasLeadingAddon.value,
        'rounded-r-md': hasLeadingAddon.value && !(hasTrailingAddon.value || hasAddonButton.value),
        'pl-10': hasLeadingIcon.value,
        'pr-10': hasTrailingIcon.value,
        'pr-14': props.trailingUnit,
        'pr-14 truncate': props.truncate,
        'focus:ring-primary-500 focus:border-primary-500 border-gray-300': !error.value,
        'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300': error.value,
        'bg-gray-100': disabled.value,
        'cursor-pointer': asButton.value,
      },
    ]);

    const openSupportPage = () => {
      window.open(props.infoLink, '_blank');
    };

    const toggleType = ref(props.type);
    const toggle = () => {
      toggleType.value = toggleType.value === 'text' ? 'password' : 'text';
    };

    const value = ref(props.modelValue);

    return {
      toggle,
      toggleType,
      hasLeadingIcon,
      hasTrailingIcon,
      hasLeadingAddon,
      hasTrailingAddon,
      hasAddonButton,
      furtherHtmlAttrs,
      inputWrapperCssClasses,
      inputCssClasses,
      value,
      onInput({ target }) {
        emit('update:modelValue', target.value);
      },
      onAddonButtonClick() {
        emit('addon-button-click');
      },
      inputRef,
      openSupportPage,
    };
  },
  watch: {
    modelValue(newValue) {
      this.value = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
  .add-button:deep(.base-button) {
    @apply shadow-none focus:ring-offset-0 #{!important};
    @apply -ml-px rounded-l-none focus:z-30 #{!important};
  }
</style>
