import Fuse from 'fuse.js';

const sameOrganisationBoostFactor = 0.4;

const toRenderableList = (searchResults) => searchResults.map((result) => result.item);

const tokenize = (query) => query.split(' ').map((word) => {
  if (word && word !== query) {
    return ` '${word}`;
  }
  return '';
}).join(' ');

const buildSearchQuery = (searchTerm) => {
  const query = searchTerm.toLowerCase();
  const tokens = tokenize(query);
  const searchQuery = tokens.length > 0
    ? `${query} | ${tokens}`
    : query;
  return searchQuery;
};

const getObjectProperty = (object, key) => {
  if (key.includes('.')) {
    const keys = key.split('.');
    const value = keys.reduce((acc, currentKey) => acc[currentKey], object);
    return value;
  }
  return object[key];
};

const calculateScore = (result, keys, groupBy) => {
  if (!groupBy) {
    return result;
  }
  if (keys.length !== 2) {
    return result;
  }

  return result.map((item) => ({
    ...item,
    score: getObjectProperty(item.item, keys[1]) === groupBy
      ? item.score * sameOrganisationBoostFactor
      : item.score,
  })).sort((a, b) => a.score - b.score);
};

/* eslint-disable import/prefer-default-export */
export const search = (searchTerm, options, keys, groupBy) => {
  const fuse = new Fuse(options, {
    keys,
    includeScore: true,
    useExtendedSearch: true,
  });
  let fuzzyResult = null;
  if (!searchTerm) {
    fuzzyResult = options
      .map((val) => ({
        item: Object.assign(val, {}),
        matches: [],
        score: 0.1,
      }));
  } else {
    fuzzyResult = fuse.search(buildSearchQuery(searchTerm));
  }

  const results = calculateScore(fuzzyResult, keys, groupBy);
  return toRenderableList(results);
};
