<template>
  <div class="mb-6">
    <div class="mb-6">
      <PageHeading>
        {{ title }}
        <template
          v-if="!organisationIsNewRecord"
          #actions
        >
          <BaseButton
            icon="CheckIcon"
            :is-loading="submitting"
            :is-disabled="false"
            qa-class="save-button"
            @click="submitOrganisationForm"
          >
            <slot>
              {{ $t('defaults.save') }}
            </slot>
          </BaseButton>
        </template>
      </PageHeading>
    </div>
    <form
      ref="organisationForm"
      :action="actionUrl"
      accept-charset="UTF-8"
      method="post"
      enctype="multipart/form-data"
      @submit="submitting = true"
    >
      <input
        type="hidden"
        name="_method"
        :value="dataMethod"
      >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <div class="space-y-5 mb-5">
        <TwoColumnCard
          :header="$t('components.organisation_form.categories.general.header')"
          :helptext="$t('components.organisation_form.categories.general.helptext')"
        >
          <div class="space-y-6">
            <BaseInput
              v-model="organisation.name"
              name="organisation[name]"
              type="text"
              :error="getError('name')"
              qa-class="organisation-name"
              :label="$t('activerecord.attributes.organisation.name')"
            />
            <BaseInput
              v-model="organisation.unit"
              name="organisation[unit]"
              type="text"
              :error="getError('unit')"
              :label="$t('activerecord.attributes.organisation.unit')"
              :hint="$t('defaults.optional')"
              :description="$t('components.organisations.unit_description')"
            />
            <BaseTextarea
              v-model="organisation.description"
              :disabled="organisation.isCompleted"
              name="organisation[description]"
              :error="getError('description')"
              :label="$t('activerecord.attributes.organisation.description')"
              :hint="$t('defaults.optional')"
            />
            <RadioList
              v-model="organisation.access"
              :label="$t('activerecord.attributes.organisation.access')"
              name="organisation[access]"
              :options="accessOptions"
              :error="getError('access')"
              :disabled="organisation.isCompleted"
            />
          </div>
        </TwoColumnCard>

        <WorkflowSettingsCard
          form-attribute-prefix="organisation[organisation_workflow_setting_attributes]"
          :workflow-settings="organisation.organisationWorkflowSetting"
        />

        <TwoColumnCard
          :header="$t('components.organisation_form.categories.branding.header')"
          :helptext="$t('components.organisation_form.categories.branding.helptext')"
        >
          <div class="space-y-6">
            <ColorPicker
              v-model="organisation.color"
              name="organisation[color]"
              :error="getError('color')"
            />
            <SingleFileUpload
              :file="organisation.logo"
              accepted-mime-types="image/png, image/jpeg"
              accepted-file-types="PNG / JPG"
              name="organisation[logo]"
              destroy-name="destroy_logo"
              :error="getError('logo')"
            />
          </div>
        </TwoColumnCard>
        <div
          v-if="organisationIsNewRecord"
          class="flex justify-end space-x-5"
        >
          <BaseButton
            as="a"
            href="/organisations"
            :is-disabled="submitting"
            :is-primary="false"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
          <SaveButton
            :is-loading="submitting"
          >
            {{ $t('defaults.save') }}
          </SaveButton>
        </div>
      </div>
    </form>

    <template v-if="!organisationIsNewRecord">
      <TwoColumnCard
        :header="$t('components.organisation_form.categories.delete.header')"
        :helptext="$t('components.organisation_form.categories.delete.helptext')"
        custom-style="bg-red-50 border border-red-600 rounded-md"
      >
        <div class="flex flex-col items-end w-full h-full">
          <BaseButton
            :is-disabled="false"
            class="bg-red-600 hover:bg-red-700"
            qa-class="organisation-delete-button"
            @click="showDeleteModal = true"
          >
            {{ $t('components.organisation_form.categories.delete.header') }}
          </BaseButton>
        </div>
      </TwoColumnCard>

      <DeleteModal
        :title="$t('components.organisation_form.categories.delete.header')"
        :challenge="organisation.name"
        :open="showDeleteModal"
        :message="deleteMessage"
        :delete-url="actionUrl"
        item-type="Organisation"
        @close="showDeleteModal = false"
      />
    </template>
  </div>
</template>

<script>
import { organisationsPath, organisationPath } from '@/util/url-helpers';
import { defineComponent, ref, computed } from 'vue';

import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseTextarea from '@/components/generic/BaseTextarea/BaseTextarea.vue';
import ColorPicker from '@/components/generic/ColorPicker/ColorPicker.vue';
import SingleFileUpload from '@/components/generic/SingleFileUpload/SingleFileUpload.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import { useI18n } from 'vue-i18n';
import DeleteModal from '@/components/generic/DeleteModal/DeleteModal.vue';
import RadioList from '@/components/generic/RadioList/RadioList.vue';
import WorkflowSettingsCard from '@/components/WorkflowSettingsCard/WorkflowSettingsCard.vue';

export default defineComponent({
  name: 'OrganisationForm',
  components: {
    TwoColumnCard,
    BaseInput,
    BaseTextarea,
    ColorPicker,
    SingleFileUpload,
    BaseButton,
    SaveButton,
    PageHeading,
    WorkflowSettingsCard,
    DeleteModal,
    RadioList,
  },
  props: {
    organisationData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { t } = useI18n();

    const organisation = { ...props.organisationData };

    const title = computed(() => (!organisation.name ? t('components.organisation_form.title.new')
      : `${organisation.name}${organisation.unit ? ` / ${organisation.unit}` : ''}`));

    const organisationIsNewRecord = !organisation.id;

    const accessOptions = [
      {
        value: 'public_access',
        name: t('activerecord.enums.organisation.access.public'),
        description: t('components.organisation_form.access.values.public_access.description'),
      },
      {
        value: 'private_access',
        name: t('activerecord.enums.organisation.access.private'),
        description: t('components.organisation_form.access.values.private_access.description'),
      }];

    const organisationForm = ref(null);
    const dataMethod = organisationIsNewRecord ? 'post' : 'patch';

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    const actionUrl = organisationIsNewRecord ? organisationsPath() : organisationPath(organisation);

    const submitting = ref(false);

    function submitOrganisationForm() {
      submitting.value = true;
      organisationForm.value.requestSubmit();
    }

    const showDeleteModal = ref(false);
    const deleteMessage = t(
      'components.organisation_form.categories.delete.delete_message',
      { name: organisation.name },
    );

    return {
      title,
      submitting,
      organisationIsNewRecord,
      organisation,
      accessOptions,
      organisationForm,
      dataMethod,
      actionUrl,
      showDeleteModal,
      deleteMessage,

      getError,
      submitOrganisationForm,
    };
  },
});
</script>
